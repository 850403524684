import axios from './axios';
import {DISCOUNT, USER} from './urls';

export default {
  async getAll() {
    try {
      return await axios.get(DISCOUNT.ALL);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async getOne(id) {
    try {
      return await axios.get(DISCOUNT.ONE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async insert(params) {
    try {
      return await axios.post(DISCOUNT.INSERT, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async update(params) {
    try {
      return await axios.post(DISCOUNT.UPDATE, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async delete(id) {
    try {
      return await axios.get(DISCOUNT.DELETE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  },
};

