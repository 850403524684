import React, {useEffect, useRef, useState} from "react";
import bookAPI from '../../../api/book';
import DetailHeader from "../../../Components/DetailHeader";
import {Card, CardBody, Col, Form, Row} from "reactstrap";

const headerInfo = {
  parentLabel: 'Book List',
  parentLink: '/tc/book',
  current: 'Book Add',
  source: 'Tefl Consultants'
};

const defaultBook = {
  title: '',
  price: '',
  priceAdjusted: ''
};

const initialState = {
  fileName: '',
  length: 0,
  list: undefined,
};

const TCBookEdit = ({match, history}) => {
  const [book, setBook] = useState(defaultBook);
  const fileRef = useRef(null);
  const [fileInfo, setFileInfo] = useState(initialState);
  const [isReady, setIsReady] = useState(false);
  const {id} = match.params;

  const evChange = ({target}) => {
    const {name, value} = target;
    setBook({
      ...book,
      [name]: value,
    });
  };

  const evFileChange = async () => {
    console.log(fileRef.current.files);
    if (fileRef.current.files.length !== 0) {
      setFileInfo(f => ({...f, fileName: fileRef.current.files[0].name}));
    }
  };

  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };

  const saveFile = async newId => {
    const {files} = fileRef.current;
    const f = files[0];
    const file = renameFile(f, `${newId}-${f.name}`);

    const formData = new FormData();
    formData.append("file", file);
    // return await fetch("http://localhost:8081/tc/book/upload", { //todo - update url
    return await fetch("https://payments.teflconsultants.com:8081/tc/book/upload", { //todo - update url
      method: 'POST',
      body: formData
    });
  };

  const evSave = async () => {
    const data = {
      ...book,
      priceAdjusted: parseInt(book.priceAdjusted, 10),
      price_rmb: parseInt(book.priceAdjusted, 10) * 100
    };


    if (Number.isNaN(data.price) || Number.isNaN(data.priceAdjusted)) return alert("Invalid price");
    if (book.title.trim() === '') return alert("Please set a book a title");
    let result;
    if (id) {
      result = await bookAPI.update(data);
      if (result === 1) {
        return history.push('/tc/book');
      } else alert("Something went wrong");
    } else {
      result = await bookAPI.insert(data);
      console.log(result);
      if (result && result !== 0) {
        await saveFile(result)
          .then(res => {
            if (res.ok && res.status === 200) {
              return history.push('/tc/book');
            } else alert("Something went wrong");
          });
      }
    }
  };

  const evDelete = async () => {
    if (window.confirm("Are you sure?")) {
      const result = await bookAPI.delete(id);
      if (result === 1) {
        history.push('/tc/book');
      } else {
        alert("something went wrong!");
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (id) {
        headerInfo.current = 'Book Edit';
        const result = await bookAPI.getOne(id);
        console.log(result);
        if (result) {
          setBook(result);
        }
      }
    }

    fetchData();
  }, [id]);

  return (
    <>
      <DetailHeader headerInfo={headerInfo}/>
      <Card>
        <CardBody>

          <Col sm={6}>
            <Row>
              {id &&
              <h4>Book Edit</h4>
              }
              {!id &&
              <h4>Book Add</h4>
              }
            </Row>
          </Col>

          <Row className="form-group align-items-center mb-3">
            <Col sm={6}>
              <Row className="align-items-center mb-3">
                <Col sm={2}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    Title
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    className="form-control"
                    name="title"
                    value={book.title || ''}
                    onChange={evChange}
                  />
                </Col>
                <Col sm={2}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    Price
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    className="form-control"
                    name="priceAdjusted"
                    value={book.priceAdjusted || ''}
                    onChange={evChange}
                  />
                </Col>
              </Row>
              <Row className="form-group align-items-center mb-3">
                <Col lg={3}>
                  <input
                    type="file"
                    tabIndex="-1"
                    id="excelUpload"
                    style={{display: 'none'}}
                    ref={fileRef}
                    onChange={evFileChange}
                  />
                  <label
                    htmlFor="excelUpload"
                    className="btn btn-success waves-effect waves-light col-form-label"
                  >
                    Select file
                  </label>
                </Col>
                {fileInfo.fileName.length > 0 &&
                  <Col lg={9}>
                    <input
                      className="form-control"
                      value={fileInfo.fileName}
                      disabled
                    />
                  </Col>
                }
              </Row>
            </Col>
          </Row>
          <Row className="form-group align-items-center justify-content-center button-items">
            <button
              type="button"
              onClick={evSave}
              className="btn btn-primary waves-effect waves-light"
            >
              Save
            </button>
            {id &&
            <button
              type="button"
              onClick={evDelete}
              className="btn btn-danger waves-effect waves-light"
            >
              Delete
            </button>}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
export default TCBookEdit;
