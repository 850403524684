import axios from "./axios";
import {CONSULTANCY} from "./urls";

export default {
  async getOne(id) {
    try {
      return await axios.get(CONSULTANCY.GET_ONE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  async getAll() {
    try {
      return await axios.get(CONSULTANCY.GET_ALL);
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  async update(params) {
    try {
      return await axios.post(CONSULTANCY.UPDATE, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  async insert(params) {
    try {
      return await axios.post(CONSULTANCY.INSERT, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  async delete(id) {
    try {
      return await axios.delete(CONSULTANCY.DELETE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
