import {Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import React from "react";
import './DetailHeader.css';

const DetailHeader = ({headerInfo}) => {
    const {parentLabel, parentLink, current, trail, source} = headerInfo;

    let key = 0;
    const getKey = () => {
      key += 1;
      return key;
    }

    let trailHTML = <></>;

    if (trail && Array.isArray(trail)) {
      trailHTML = trail.map(t => {
        return <li className="breadcrumb-item" key={getKey}>{t}</li>
      });
    }

    return (
      <div className="burger-margin">
        <Row className="align-items-center">
          <Col sm={6}>
            <div className="page-title-box">
              <h4 className="font-size-18">{source}</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={parentLink}>{parentLabel}</Link>
                </li>
                {
                  trailHTML
                }
                <li className="breadcrumb-item active">{current}</li>
              </ol>
            </div>
          </Col>

        </Row>
      </div>
    );
};

export default DetailHeader;
