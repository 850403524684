import React, {useEffect, useState} from "react";
import discountAPI from "../../../api/discount";
import DetailHeader from "../../../Components/DetailHeader";
import {CardBody, Col, Card, Row} from "reactstrap";

const defaultDiscount = {
  id: '',
  code: '',
  percentage: '',
  owner: ''
};

const headerInfo = {
  parentLabel: 'Discount',
  parentLink: '/discount',
  current: 'Add discount',
  source: 'Train The Teacher'
};

const DiscountEdit = ({history, match}) => {
  const [discount, setDiscount] = useState(defaultDiscount);
  const {id} = match.params;

  const evChange = ({target}) => {
    const {name, value} = target;
    setDiscount({
      ...discount,
      [name]: value,
    });
  };

  const evSave = async () => {
    const result = await discountAPI.insert(discount);
    if (result && result === 1) {
      history.push('/discount');
    }
  };

  useEffect(() => {
    async function fetchData() {
      const result = await discountAPI.getOne(id);
      console.log(result);
      if (result) {
        setDiscount(result);
      }
    }
    fetchData();
    headerInfo.current = 'Edit Discount Code';
  }, [id]);

  return(<>
    <DetailHeader headerInfo={headerInfo} />
    <Card>
      <CardBody>

          <Col sm={6}>
            <Row>
              {id &&
                <h4>Edit Discount Code</h4>
              }
              {!id &&
                <h4>Add Discount Code</h4>
              }
            </Row>
          </Col>

        <Row className="form-group align-items-center mb-3">
          <Col sm={2}>
            <label className="col-sm-12 col-form-label bg-secondary rounded">
              Code
            </label>
          </Col>
          <Col sm={4}>
            <input
              className="form-control"
              name="code"
              value={discount.code || ''}
              onChange={evChange}
            />
          </Col>
        </Row>
        <Row className="form-group align-items-center mb-3">
          <Col sm={2}>
            <label className="col-sm-12 col-form-label bg-secondary rounded">
              Percentage
            </label>
          </Col>
          <Col sm={4}>
            <input
              className="form-control"
              name="percentage"
              value={discount.percentage || ''}
              onChange={evChange}
            />
          </Col>
        </Row>
        <Row className="form-group align-items-center mb-3">
          <Col sm={2}>
            <label className="col-sm-12 col-form-label bg-secondary rounded">
              Owner
            </label>
          </Col>
          <Col sm={4}>
            <input
              className="form-control"
              name="owner"
              value={discount.owner || ''}
              onChange={evChange}
            />
          </Col>
        </Row>
        <Row className="form-group align-items-center justify-content-center button-items">
          <button
            type="button"
            onClick={evSave}
            className="btn btn-primary waves-effect waves-light"
          >
            Save
          </button>
          <button
            type="button"
            onClick={() => setDiscount(defaultDiscount)}
            className="btn btn-info waves-effect waves-light"
          >
            Clear
          </button>
        </Row>
      </CardBody>
    </Card>
  </>);
};
export default DiscountEdit;
