import {MDBDataTableV5} from 'mdbreact';
import React from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
// import {excelDL} from '../../util/excel';
import './GridStyles.css';

type TableFormat = {
    data: {
        columns: {
            label: string;
            field: string;
            sort?: string;
            width?: number;
            searchable?: boolean;
            minimal: string;
        }[];
        rows: any;
    };
    evClickBtn: any;
    btn: {
        val?: string;
        class?: string;
    }
    excelData: any;
};

function GridTable({data, evClickBtn, btn}: TableFormat) {


    const btnData = {val: 'Add', class: 'btn btn-sm waves-effect waves-light btn-primary'};
    if (btn && btn.val) {
        btnData.val = btn.val;
    }

    if (btn && btn.class) {
        btnData.class = `btn btn-sm waves-effect waves-light ${btn.class}`;
    }

    return (
        <Card>
            <CardBody>
                <div className="button-right">
                    <div className="d-flex justify-content-between align-items-end align-content-end">
                        {evClickBtn && (

                            <button
                                className={btnData.class}
                                onClick={evClickBtn}
                            >
                                {btnData.val}
                            </button>
                        )}
                    </div>
                </div>
                <MDBDataTableV5
                    responsive
                    bordered
                    striped
                    entriesOptions={[10, 30, 50]}
                    entries={10}
                    data={data}
                />
            </CardBody>
        </Card>
    );
}

export default React.memo(GridTable);
