import React, {useEffect, useState} from "react";
import courseAPI from "../../../api/course";
import DetailHeader from "../../../Components/DetailHeader";
import {CardBody, Col, Card, Row} from "reactstrap";

const defaultCourse = {
  id: '',
  name: '',
  priceAdjusted: '',
};

const headerInfo = {
  parentLabel: 'Course List',
  parentLink: '/course',
  current: 'Course Edit',
  source: 'Train The Teacher'
};

const CourseEdit = ({history, match}) => {
  const [course, setCourse] = useState(defaultCourse);
  const {id} = match.params;

  const evChange = ({target}) => {
    const {name, value} = target;
    setCourse({
      ...course,
      [name]: value,
    });
  };

  const evSave = async () => {
    const result = await courseAPI.insert(course);
    if (result && result === 1) {
      history.push('/course');
    }
  };

  useEffect(() => {
    async function fetchData() {
      const result = await courseAPI.getOne(id);
      console.log(result);
      if (result) {
        setCourse(result);
      }
    }
    fetchData();
    headerInfo.current = 'Edit Course';
  }, [id]);

  return(<>
    <DetailHeader headerInfo={headerInfo} />
    <Card>
      <CardBody>

          <Col sm={6}>
            <Row>
              {id &&
                <h4>Edit Course</h4>
              }
              {!id &&
                <h4>Add Course</h4>
              }
            </Row>
          </Col>

        <Row className="form-group align-items-center mb-3">
          <Col sm={2}>
            <label className="col-sm-12 col-form-label bg-secondary rounded">
              Name
            </label>
          </Col>
          <Col sm={4}>
            <input
              className="form-control"
              name="name"
              value={course.name || ''}
              onChange={evChange}
            />
          </Col>
        </Row>
        <Row className="form-group align-items-center mb-3">
          <Col sm={2}>
            <label className="col-sm-12 col-form-label bg-secondary rounded">
              Price
            </label>
          </Col>
          <Col sm={4}>
            <input
              className="form-control"
              name="priceAdjusted"
              value={course.priceAdjusted || ''}
              onChange={evChange}
            />
          </Col>
        </Row>
        <Row className="form-group align-items-center justify-content-center button-items">
          <button
            type="button"
            onClick={evSave}
            className="btn btn-primary waves-effect waves-light"
          >
            Save
          </button>
          <button
            type="button"
            onClick={() => setCourse(defaultCourse)}
            className="btn btn-info waves-effect waves-light"
          >
            Clear
          </button>
        </Row>
      </CardBody>
    </Card>
  </>);
};
export default CourseEdit;
