import discountAPI from '../../../api/discount';
import React, {useEffect, useState} from "react";
import DetailHeader from "../../../Components/DetailHeader";
import {CardBody, Col, Row, Card} from "reactstrap";
import GridTable from "../../../Components/GridTable";
import {Link} from "react-router-dom";

const columns = [
  {
    field: 'id',
    label: 'id'
  },
  {
    field: 'code',
    label: 'Code'
  },
  {
    field: 'percentage',
    label: 'Percentage'
  },
  {
    field: 'owner',
    label: 'Owner'
  }
];

const headerInfo = {
  parentLabel: 'Home',
  parentLink: '/',
  current: 'Discount Codes',
  source: 'Train The Teacher'
};


const DiscountList = ({history}) => {
  const [apiData, setApiData] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(
      apiData.map(d => ({
        ...d,
        code: <Link to={`discount/${d.id}`}>{d.code}</Link>,
        percentage: `${d.percentage}%`
      }))
    );
  }, [apiData]);

  useEffect(() => {
    async function fetchData() {
      const result = await discountAPI.getAll();
      console.log(result);
      if (result && Array.isArray(result)) {
        setApiData(result);
      }
    }
    fetchData();
  }, []);

  return(
    <>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Col sm={9}>
            <Row>
              <h4>Discount Codes</h4>
            </Row>
          </Col>
          <GridTable data={{columns, rows: rowData}} evClickBtn={() => history.push('/discount/add')}/>
        </CardBody>
      </Card>
    </>
  );
};
export default DiscountList;
