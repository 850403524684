import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import statsAPI from '../api/stats';
import DashTile from "../Components/DashTile";
import {findThisMonthStart, findLastMonthStart, calcDateRangeForSalesReport} from "../Utils/time";
import './homeStyles.css';
import book from '../assets/images/services-icon/book.png';
import consult from '../assets/images/services-icon/consultancy.png';
import course from '../assets/images/services-icon/02.png';
import RadialChart from "../Components/apexdonut";
import SalesReport from "../Components/SalesReport";


const defaultTile = {
  title: '',
  imgSrc: '',
  ribbon: '',
  change: '',
  arrow: '',
  linkPath: ''
};

const defaultStats = {
  monthStart: 0,
  lastMonthStart: 0,
  booksThisMonth: 0,
  booksLastMonth: 0,
  consultanciesThisMonth: 0,
  consultanciesLastMonth: 0
};

const defaultSales = {
  date: '',
  total: '',
  series: null,
  labels: [''],
};

const HomePage = () => {
  const [TCStats, setTCStats] = useState(defaultStats);
  const [bookTile, setBookTile] = useState(defaultTile);
  const [consultTile, setConsultTile] = useState(defaultTile);
  const [salesTile, setSalesTile] = useState(defaultSales);

  const [TTTStats, setTTTStats] = useState(defaultStats);
  const [coursesTile, setCoursesTile] = useState(defaultTile);
  const [thisMonthStart, setThisMonthStart] = useState();
  const [lastMonthStart, setLastMonthStart] = useState();

  const calcRibbonValue = (thisMonth, lastMonth) => {
    if (thisMonth === 0 && lastMonth === 0) return 0;
    if (thisMonth !== 0 && lastMonth === 0) return thisMonth * 100;
    if (thisMonth === 0 && lastMonth !== 0) return lastMonth * -100;
    if (thisMonth >= lastMonth) {
      return thisMonth / lastMonth * 100;
    } else {
      return (thisMonth / lastMonth * -100);
    }
  };

  const calcChangeValue = (thisMonth, lastMonth) => {
    if (thisMonth >= lastMonth) {
       return `+${thisMonth - lastMonth}`;
    } else {
      return `-${thisMonth - lastMonth}`;
    }
  };

  const getArrowType = (thisMonth, lastMonth) => {
    if (thisMonth > lastMonth) return 'up';
    if (thisMonth < lastMonth) return 'down';
    return 'noChange'
  };

  const calcDonutVal = sales => {
    const s = sales.toString();
    return sales/Math.pow(10, s.length - 2);
  };


  // Calc millisecond values for query params
  useEffect(() => {
    const val = findThisMonthStart();
    setThisMonthStart(val);
    setLastMonthStart(findLastMonthStart(val -1));
  }, []);

  // Prepare TC stat data
  useEffect(() => {
    if (TCStats.bookSales === undefined) return;
    setBookTile({
      title: 'Books',
      imgSrc: book,
      ribbon: calcRibbonValue(TCStats.booksThisMonth, TCStats.booksLastMonth),
      change: calcChangeValue(TCStats.booksThisMonth, TCStats.booksLastMonth),
      arrow: getArrowType(TCStats.booksThisMonth, TCStats.booksLastMonth),
      linkPath: '',
      cardClass: "mini-stat bg-primary text-white box-shadow"
    });
    setConsultTile({
      title: 'Consultancies',
      imgSrc: consult,
      ribbon: calcRibbonValue(TCStats.consultanciesThisMonth, TCStats.consultanciesLastMonth),
      change: calcChangeValue(TCStats.consultanciesThisMonth, TCStats.consultanciesLastMonth),
      arrow: getArrowType(TCStats.consultanciesThisMonth, TCStats.consultanciesLastMonth),
      linkPath: '',
      cardClass: "mini-stat bg-warning text-white box-shadow"
    });
    setSalesTile({
      date: calcDateRangeForSalesReport(),
      total: TCStats.bookSales + TCStats.consultancySales,
      series: [TCStats.bookSales, TCStats.consultancySales],
      labels: ['Books', 'Consultancies']
    });
  }, [TCStats]);

  // Prepare TTT stat data
  useEffect(() => {
    if (TTTStats.courseSales === undefined) return;
    setCoursesTile({
      title: 'Courses',
      imgSrc: course,
      ribbon: calcRibbonValue(TTTStats.coursesThisMonth, TTTStats.coursesLastMonth),
      change: calcChangeValue(TTTStats.coursesThisMonth, TTTStats.coursesLastMonth),
      arrow: getArrowType(TTTStats.coursesThisMonth, TTTStats.coursesLastMonth),
      linkPath: '',
      cardClass: "mini-stat bg-success text-white box-shadow",
      donutVal: calcDonutVal(TTTStats.courseSales),
      sales: TTTStats.courseSales
    });
  }, [TTTStats]);

  // Fetch TC stats from backend
  useEffect(() => {
    async function fetchData() {
      if (!thisMonthStart || !lastMonthStart) return;
      const params = {monthStart: thisMonthStart, lastMonthStart};
      const TCResult = await statsAPI.getTCStats(params);
      console.log(TCResult);
      if (TCResult) {
        setTCStats(TCResult);
      }
      const TTTResult = await statsAPI.getTTTStats(params);
      console.log(TTTResult);
      if (TTTResult) {
        setTTTStats(TTTResult);
      }
    }
    fetchData();
  }, [lastMonthStart, thisMonthStart]);




  return(
    <div className="burger-avoid">
      <Card>
        <CardBody>
          <Col sm={12}>
            <Row className="align-content-center justify-content-center">
              <h3 className="mb-4">Sales Report: TEFL Consultants</h3>
              <div className="clearfix">
                <p className="ml-3 large">
                  <i className="mdi mdi-calendar mr-1 text-primary large" /> {calcDateRangeForSalesReport()}
                </p>
              </div>
            </Row>
          </Col>
          <Col sm={12}>
            <Row className="align-items-center mb-3 col-sm-12 row-shadow row-bg pt-4">
              <Col sm={4}>
                <DashTile props={bookTile}/>
              </Col>
              <Col sm={4}>
                <DashTile props={consultTile}/>
              </Col>
              <Col sm={4}>
                {salesTile.series &&
                <SalesReport props={salesTile}/>
                }
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="mt-5">
            <Row className="align-content-center justify-content-center">
              <h3 className="mb-4">Sales Report: Train The Teacher</h3>
              <div className="clearfix">
                <p className="ml-3 large">
                  <i className="mdi mdi-calendar mr-1 text-primary large" /> {calcDateRangeForSalesReport()}
                </p>
              </div>
            </Row>
          </Col>
          <Col sm={12}>
            <Row className="align-items-center mb-3 col-sm-12 row-shadow row-bg pt-4">
              <Col sm={4}>
                <DashTile props={coursesTile}/>
              </Col>
              <Col sm={4}>
                {coursesTile.donutVal &&
                  <>
                    <div className="text-center">
                      <p className="text-muted mb-1">This month</p>
                      <h3>{coursesTile.sales}¥</h3>
                    </div>
                    <RadialChart val={coursesTile.donutVal}/>
                  </>
                }
              </Col>
            </Row>
          </Col>
        </CardBody>
      </Card>

    </div>
  );
};
export default HomePage;
