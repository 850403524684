import React from 'react';
import ReactApexChart from 'react-apexcharts';

const RadialChart = ({val}) => {
  const options= {
      plotOptions: {
        radialBar: {
          hollow: {
            size: '45%',
          },
          dataLabels: {
            value: {
              show: false,
            },
          },
        },
      },
      colors: ['#02a499'],
      labels: [''],
    };
  const series = [val];

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height="140px"
      />
    </>
  );
};


export default RadialChart;
