import {useState} from "react";

export default function useToken() {
  const getToken = () => {
    return localStorage.getItem('api-token');
  };

  const [token, setToken] = useState(getToken());
  const saveToken = (token) => {
    localStorage.setItem("api-token", token);
    setToken(token);
  };
  return {
    setToken: saveToken,
    token
  };

}

