import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SalesDonut = ({props}) => {
  const {series, labels} = props;
  const options = {
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
        },
      },
    },
    colors: ['#626ed4', '#f8b425', '#f8b425'],
    labels
  };
  // const series = [54, 28, 17];

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="155"
      />
    </>
  );
};
export default SalesDonut;
