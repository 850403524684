import axios from 'axios';
import {API_URL} from './urls';

let AUTH_TOKEN = localStorage.getItem('api-token');

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    authorization: 'Bearer ' + AUTH_TOKEN,
    // 'content-type': 'application/json; charset=utf-8',
    // 'content-type': 'multipart/mixed'
  },
});
// const authorization = 'Authorization';
// const contentType = 'Content-Type';
// instance.defaults.headers.common[authorization] = 'Bearer ' + AUTH_TOKEN;
// instance.defaults.headers.common[contentType] =
//   'application/json; charset=utf-8';

instance.interceptors.response.use(
  response => {
    // Do something with response data
    return response.data;
  },
  error => {
    // Do something with response error
    console.log(`----------> ERROR RESPONSE : ${error.config.url}`);
    console.log(`----------> ERROR MESSAGE  : ${error.message}`);
    return Promise.reject(error);
  },
);

export default instance;
