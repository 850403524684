export const parseStatus = status => {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Completed";
    case 2:
      return "Insuffecient funds";
    case 3:
      return "Invalid amount";
    case 4:
      return "Disputed";
    case 5:
      return "Cancelled";
    case 6:
      return "Unknown error";
  }
};
