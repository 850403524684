import React, {useEffect, useState} from "react";
import DetailHeader from "../../../Components/DetailHeader";
import {Card, Col, Row, CardBody} from "reactstrap";
import GridTable from "../../../Components/GridTable";
import userAPI from '../../../api/tc_user';
import {Link} from "react-router-dom";
import BookModal from "../../../Components/BookModal";
import './styles.css';
import ConsultancyModal from "../../../Components/ConsultancyModal";
import {parseUnixTime} from "../../../Utils/time";
import {parseStatus} from "../../../Utils/alipay";

const headerInfo = {
  parentLabel: 'Home',
  parentLink: '/',
  current: 'Users',
  source: 'Tefl Consultants'
};

const columns = [
  {
    label: 'id',
    field: 'id',
  },
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'company',
    field: 'company'
  },
  {
    label: 'Job',
    field: 'jobTitle'
  },
  {
    label: 'Product',
    field: 'product'
  },
  {
    label: 'Payment Amount',
    field: 'paymentAdjusted',
  },
  {
    label: 'Status',
    field: 'status'
  },
  {
    label: 'Date',
    field: 'date',
  },
];

const TCUserList = ({history}) => {
  const [apiData, setApiData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [book, setBook] = useState({});
  const [consultancy, setConsultancy] = useState({});

  useEffect(() => {
    async function fetchData() {
      const result = await userAPI.getAll();
      console.log(result);
      if (result && Array.isArray(result)) {
        result.reverse();
        setApiData(result);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setTableData(
      apiData.map(d => ({
        ...d,
        name: <Link to={`/tc/user/${d.id}`}>{d.name}</Link>,
        email: <a href={`mailto: ${d.email}`}>{d.email}</a>,
        product: <a onClick={() => {
          if (d.product === 'book') {
            setBook(d.book);
          } else if (d.product === 'consultancy') {
            setConsultancy(d.consultancy)
          }
        }}
        className="asLink"
        >{d.product}</a>,
        paymentAdjusted: `${d.paymentAdjusted}¥`,
        status: parseStatus(d.status),
        date: parseUnixTime(d.date)
      }))
    );
  }, [apiData]);

  return (
    <>
      <DetailHeader headerInfo={headerInfo}/>
      <Card>
        <CardBody>
          <Col sm={6}>
            <Row>
              <h4>Users list</h4>
            </Row>
          </Col>
        </CardBody>
      </Card>
      <GridTable data={{columns, rows: tableData}}/>
      <BookModal book={book} callback={() => setBook({})}/>
      <ConsultancyModal consultancy={consultancy} callback={() => {setConsultancy({})}} />
    </>
  );
};
export default TCUserList;
