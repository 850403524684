import axios from "./axios";

export default {
  async getTCStats(params) {
    try {
      return await axios.post('stats/tc', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  async getTTTStats(params) {
    try {
      return await axios.post('stats/ttt', params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
};
