import Loader from "../Components/Loader";
import {Alert, Card, CardBody, Col, Row} from "reactstrap";
import {useState} from "react";
import tcLogo from '../assets/images/tc-transparent.png';
import tttLogo from '../assets/images/traintheteacher.webp';
import tfhLogo from '../assets/images/TFH_logo.png';
import './loginStyles.css';

const LoginPage = ({setToken}) => {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getToken = async () => {
    fetch('https://payments.traintheteacher.org:8081/auth', { //todo
    // fetch('http://localhost:8081/auth', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: userName, password})
    })
      .then(response => {
        if (response.status === 200) {
          response.json()
            .then(data => {
              console.log(data);
              setIsLoading(false);
              setToken(data?.jwt);
              window.location.reload(); //hacky way of solving missing auth key after login
            })
        } else {
          console.log(response);
          setIsError(true);
          setIsLoading(false);
        }
      })
      .catch(err => console.error(err));
  };

  const evLogin = async e => {
    setIsLoading(true);
    e.preventDefault();
    await getToken();
  };

  return (
    <>
      <div className="account-pages my-5 pt-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="position-relative">
                {isLoading &&
                  <Loader/>
                }
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">
                        Welcome Back !
                      </h5>
                      <p className="text-white-50">
                        Sign in to continue.
                      </p>
                      <Row>
                        <Col>
                          <div className="logo logo-admin logo-ttt">
                            <img src={tfhLogo} height="52" alt="logo" />
                          </div>
                        </Col>
                        <Col>
                          <div className="logo logo-admin logo-tc">
                            <img src={tcLogo} height="50" alt="logo" />
                          </div>
                        </Col>
                        <Col>
                          <div className="logo logo-admin logo-ttt">
                            <img src={tttLogo} width="70" alt="logo" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <CardBody className="p-4">
                    <div className="p-3">
                      <form className="form-horizontal mt-4" onSubmit={evLogin}>
                        {isError &&
                          <Alert color="danger">Error logging in. Please check username and password</Alert>
                        }
                        <div className="form-group">
                          <input type="text"
                                 className="form-control"
                                 placeholder="Username"
                                 onChange={e => setUserName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input type="password"
                                 className="form-control"
                                 placeholder="Password"
                                 onChange={e => setPassword(e.target.value)}
                          />
                        </div>

                        <Row className="form-group align-items-center">
                          <Col sm={12} className="text-center">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default LoginPage;
