import React, {useEffect, useState} from "react";
import DetailHeader from "../../../Components/DetailHeader";
import {Card, CardBody, Col, Row} from "reactstrap";
import userAPI from '../../../api/ttt_users';

const headerInfo = {
  parentLabel: 'Users',
  parentLink: '/users',
  current: 'User Edit',
  source: 'Train The Teacher'
};

const defaultUser = {
  name: '',
  email: '',
  cnName: '',
  paymentAmount: '',
  courseName: '',
  allowMarketing: '',
  status: '',
  date: '',
  weChatId: '',
  phoneNumber: '',
  heardFrom: '',
  discountCode: '',
  paymentAdjusted: ''
};

const TTTUsersEdit = ({match, history}) => {
  const [user, setUser] = useState(defaultUser);
  const {id} = match.params;

  const evChange = ({target}) => {
    const {name, value} = target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const evSave = async () => {
    const result = await userAPI.update(user);
    console.log(result);
  };

  useEffect(() => {
    async function fetchData() {
      if (!id) return;
      const result = await userAPI.getOne(id);
      if (result) {
        console.log(result);
        setUser(result);
      }
    }

    fetchData();
  }, [id]);

  return (<>
    <DetailHeader headerInfo={headerInfo}/>
    <Card>
      <CardBody>

        <Col sm={6}>
          <Row>
            <h4>Users Edit</h4>
          </Row>
        </Col>

        <Row className="form-group align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Name
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="name"
                  value={user.name || ''}
                  onChange={evChange}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Email
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="email"
                  value={user.email || ''}
                  onChange={evChange}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Chinese Name
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="cnName"
                  value={user.cnName || ''}
                  onChange={evChange}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Status
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="status"
                  value={user.status || ''}
                  onChange={evChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="form-group align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Payment Amount
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="paymentAdjusted"
                  value={user.paymentAdjusted || ''}
                  disabled
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Course Name
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="courseName"
                  value={user.courseName || ''}
                  onChange={evChange}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Allow Marketing
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="allowMarketing"
                  value={user.allowMarketing || ''}
                  onChange={evChange}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Date
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="date"
                  value={user.date || ''}
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="form-group align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  WeChat
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="weChatId"
                  value={user.weChatId || ''}
                  onChange={evChange}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Phone
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="phoneNumber"
                  value={user.phoneNumber || ''}
                  onChange={evChange}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Heard From
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="heardFrom"
                  value={user.heardFrom || ''}
                  onChange={evChange}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  Discount Code
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  name="discountCode"
                  value={user.discountCode || ''}
                  onChange={evChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="form-group align-items-center justify-content-center button-items">
          <button
            type="button"
            onClick={evSave}
            className="btn btn-primary waves-effect waves-light"
          >
            Save
          </button>
        </Row>
      </CardBody>
    </Card>
  </>);
};
export default TTTUsersEdit;
