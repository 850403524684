import axios from './axios';
import {BOOK} from './urls';

export default {
  async getOne(id) {
    try {
      return await axios.get(BOOK.GET_ONE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async insert(params) {
    try {
      return await axios.post(BOOK.INSERT, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async update(params) {
    try {
      return await axios.post(BOOK.UPDATE, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async getAll() {
    try {
      return await axios.get(BOOK.GET_ALL);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(BOOK.DELETE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async upload(file) {
    try {
      return await axios.post(BOOK.UPLOAD, file);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
};

