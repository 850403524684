import axios from './axios';
import { COURSE } from './urls';

export default {
  async getAll() {
    try {
      return await axios.get(COURSE.ALL);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async getOne(id) {
    try {
      return await axios.get(COURSE.GET_ONE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async insert(params) {
    try {
      return await axios.post(COURSE.INSERT, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async update(params) {
    try {
      return await axios.post(COURSE.UPDATE, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(COURSE.DELETE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

};

