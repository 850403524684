import React, {useEffect, useState} from "react";
import consultancyAPI from '../../../api/consultancy';
import DetailHeader from "../../../Components/DetailHeader";
import {Card, CardBody, Col, Row} from "reactstrap";

const headerInfo = {
  parentLabel: 'Consultancy List',
  parentLink: '/tc/consultancy',
  current: 'Consultancy Add',
  source: 'Tefl Consultants'
};

const defaultConsultancy = {
  name: '',
  price_rmb: '',
  priceAdjusted: ''
};

const TCConsultancyEdit = ({match, history}) => {
  const [consultancy, setConsultancy] = useState(defaultConsultancy);
  const {id} = match.params;

  const evChange = ({target}) => {
    const {name, value} = target;
    setConsultancy({
      ...consultancy,
      [name]: value,
    });
  };

  const evSave = async () => {
    const data = {
      ...consultancy,
      priceAdjusted: parseInt(consultancy.priceAdjusted, 10),
      price_rmb: parseInt(consultancy.priceAdjusted, 10) * 100
    };

    if (Number.isNaN(data.price) || Number.isNaN(data.priceAdjusted)) return alert("Invalid price");
    if (consultancy.title.trim() === '') return alert("Please set a consultancy name");
    let result;
    if (id) {
      result = await consultancy.update(data);
      console.log(result);
    } else {
      result = await consultancy.insert(data);
      console.log(result);
    }
    if (result === 1) {
      return history.push('/tc/consultancy');
    } else {
      alert("Something went wrong");
    }
  };

  const evDelete = async () => {
    if (window.confirm("Are you sure?")) {
      const result = await consultancyAPI.delete(id);
      if (result === 1) {
        history.push('/tc/consultancy');
      } else {
        alert("something went wrong!");
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (id) {
        headerInfo.current = 'Consultancy Edit';
        const result = await consultancyAPI.getOne(id);
        console.log(result);
        if (result) {
          setConsultancy(result);
        }
      }
    }

    fetchData();
  }, [id]);

  return (
    <>
      <DetailHeader headerInfo={headerInfo}/>
      <Card>
        <CardBody>

          <Col sm={6}>
            <Row>
              {id &&
               <h4>Consultancy Edit</h4>
              }
              {!id &&
               <h4>Consultancy Add</h4>
              }
            </Row>
          </Col>

          <Row className="form-group align-items-center mb-3">
            <Col sm={6}>
              <Row className="align-items-center">
                <Col sm={2}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    Name
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    className="form-control"
                    name="name"
                    value={consultancy.name || ''}
                    onChange={evChange}
                  />
                </Col>
                <Col sm={2}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    Price
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    className="form-control"
                    name="priceAdjusted"
                    value={consultancy.priceAdjusted || ''}
                    onChange={evChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="form-group align-items-center justify-content-center button-items">
            <button
              type="button"
              onClick={evSave}
              className="btn btn-primary waves-effect waves-light"
            >
              Save
            </button>
            <button
              type="button"
              onClick={evDelete}
              className="btn btn-danger waves-effect waves-light"
            >
              Delete
            </button>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
export default TCConsultancyEdit;
