import {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const ConsultancyModal = ({consultancy, callback}) => {
  const [show, setShow] = useState(false);


  const handleClose = () => {
    setShow(false);
    callback();
  };

  useEffect(() => {
    if (consultancy.id) {
    setShow(true);
    } else {
      setShow(false);
    }
  }, [consultancy]);

  return(
    <>
     <Modal isOpen={show}>
       <ModalHeader><a href={`/tc/consultancy/${consultancy.id}`} className="asLink">{consultancy.name}</a></ModalHeader>
       <ModalBody>{consultancy.priceAdjusted}¥</ModalBody>
       <ModalFooter><Button color="secondary" onClick={handleClose}>Close</Button> </ModalFooter>
     </Modal>
    </>
  );

};
export default ConsultancyModal;
