import {BrowserRouter, Switch} from 'react-router-dom';
import './App.css';
import Sidebar from "./Components/Sidebar";
import {publicRoutes, authProtectedRoutes} from "./routes";
import AppRoute from './routes/route';
import LoginPage from "./pages/login";
import useToken from "./Components/useToken";
import './assets/scss/theme.scss';

function App() {
  const {token, setToken} = useToken();
  document.title = "Alipay Admin Console";

  // useEffect(() => {
  //   async function checkTokenValid() {
  //     const token = localStorage.getItem('api-token');
  //     const isValid = await authAPI.checkToken(token);
  //     console.log("Token valid", isValid);
  //     if (!isValid) setToken(null);
  //   }
  //   checkTokenValid();
  // }, [setToken])

  if (!token) {
    return <LoginPage setToken={setToken}/>
  }
  return (
    <div className="App" id="outer-container">
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'}/>
      <div id="page-wrap">
        <BrowserRouter>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected
              />
            ))}
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
