

import React, {useEffect, useState} from "react";
import consultancyAPI from '../../../api/consultancy';
import DetailHeader from "../../../Components/DetailHeader";
import {Card, CardBody, Col, Row} from "reactstrap";
import GridTable from "../../../Components/GridTable";
import {Link} from "react-router-dom";

const headerInfo = {
  parentLabel: 'Home',
  parentLink: '/',
  current: 'Consultancies',
  source: 'Tefl Consultants'
};

const columns = [
  {
    label: 'Title',
    field: 'name'
  },
  {
    label: 'Price',
    field: 'priceAdjusted'
  },
  {
    label: '',
    field: 'getLink'
  }
];

const TCConsultancyList = ({history}) => {
  const [apiData, setApiData] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(
      apiData.map(c => ({
        ...c,
        priceAdjusted: `${c.priceAdjusted}¥`,
        name: <Link to={`/tc/consultancy/${c.id}`}>{c.name}</Link>,
        getLink: <button onClick={() => window.prompt("Copy to clipboard: Ctrl+C, Enter",`https://www.payments.teflconsultants.com/teflconsultants/alipay_payment.html?consultancy=${c.id}`)}>Get Link</button>
      }))
    );
  }, [apiData]);

  useEffect(() => {
    async function fetchData() {
      const result = await consultancyAPI.getAll();
      console.log(result);
      if (result && Array.isArray(result)) {
        setApiData(result);
      } else {
        setApiData([]);
      }
    }
    fetchData();
  }, []);

  return(
    <>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Col sm={6}>
            <Row>
              <h4>Consultancy list</h4>
            </Row>
          </Col>
        </CardBody>
        <GridTable data={{columns, rows: rowData}} evClickBtn={() => {history.push('/tc/consultancy/add')}}/>
      </Card>
    </>
  );
};
export default TCConsultancyList;

