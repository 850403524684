import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const isAuthenticated = true;
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !isAuthenticated) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
        return (

            <Component {...props} />

        );
      }}
    />
  );
};

export default AppRoute;
