// export const API_URL = 'http://localhost:8081/';
export const API_URL = 'https://payments.traintheteacher.org:8081/';

// TTT
export const TTT_USER = {
  UPDATE: 'user/update',
  GET_OR_DELETE: 'user/',
  SEARCH: 'user/search/',
  GET_ALL: 'user/findall'
};

export const DISCOUNT = {
  CHECK: 'discount/checkCode/',
  INSERT: 'discount/insert',
  ALL: 'discount/all',
  DELETE: 'discount/delete/',
  UPDATE: 'discount/update',
  ONE: 'discount/'
};

export const COURSE = {
  GET_ONE: 'course/id/',
  ALL: 'course/all',
  INSERT: 'course/insert',
  DELETE: 'course/delete/',
  UPDATE: 'course/update',
};

// TC

export const TC_USER = {
  GET_ONE: 'tc/user/',
  INSERT: 'tc/user/insert',
  UPDATE: 'tc/user/update',
  GET_ALL: 'tc/user/all'
};

export const BOOK = {
  GET_ONE: 'tc/book/',
  INSERT: 'tc/book/insert',
  GET_ALL: 'tc/book',
  UPDATE: 'tc/book/update',
  DELETE: 'tc/book/',
  UPLOAD: 'tc/book/upload'
};

export const CONSULTANCY = {
  GET_ONE: 'tc/consultancy/',
  INSERT: 'tc/consultancy/insert',
  GET_ALL: 'tc/consultancy/all',
  UPDATE: 'tc/consultancy/update',
  DELETE: 'tc/consultancy/'
};
