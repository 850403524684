import {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const BookModal = ({book, callback}) => {
  const [show, setShow] = useState(false);


  const handleClose = () => {
    setShow(false);
    callback();
  };

  useEffect(() => {
    if (book.id) {
    setShow(true);
    } else {
      setShow(false);
    }
  }, [book]);

  return(
    <>
     <Modal isOpen={show}>
       <ModalHeader><a href={`/tc/book/${book.id}`} className="asLink">{book.title}</a></ModalHeader>
       <ModalBody>{book.priceAdjusted}¥</ModalBody>
       <ModalFooter><Button color="secondary" onClick={handleClose}>Close</Button> </ModalFooter>
     </Modal>
    </>
  );

};
export default BookModal;
