import courseAPI from '../../../api/course';
import React, {useEffect, useState} from "react";
import DetailHeader from "../../../Components/DetailHeader";
import {CardBody, Col, Row, Card} from "reactstrap";
import GridTable from "../../../Components/GridTable";
import {Link} from "react-router-dom";

const columns = [
  {
    field: 'id',
    label: 'id'
  },
  {
    field: 'name',
    label: 'Name'
  },
  {
    field: 'priceAdjusted',
    label: 'Price'
  },
  {
    field: 'getLink',
    label: ''
  }
];

const headerInfo = {
  parentLabel: 'Home',
  parentLink: '/',
  current: 'Course List',
  source: 'Train The Teacher'
};


const CourseList = ({history}) => {
  const [apiData, setApiData] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(
      apiData.map(d => ({
        ...d,
        name: <Link to={`course/${d.id}`}>{d.name}</Link>,
        getLink: <button onClick={() => window.prompt("Copy to clipboard: Ctrl+C, Enter",`https://www.payments.traintheteacher.org/alipay-payment/alipay_payment.html?course=${d.id}`)}>Get Link</button>
      }))
    );
  }, [apiData]);

  useEffect(() => {
    async function fetchData() {
      const result = await courseAPI.getAll();
      console.log(result);
      if (result && Array.isArray(result)) {
        setApiData(result);
      }
    }
    fetchData();
  }, []);

  return(
    <>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Col sm={9}>
            <Row>
              <h4>Courses</h4>
            </Row>
          </Col>
          <GridTable data={{columns, rows: rowData}} evClickBtn={() => history.push('/course/add')}/>
        </CardBody>
      </Card>
    </>
  );
};
export default CourseList;
