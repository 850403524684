import {Card, CardBody, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";

const DashTile = ({props}) => {
  const {title, imgSrc, ribbon, change, arrow, linkPath, cardClass} = props;
  return (
    <>
      <Card className={cardClass}>
        <CardBody>
          <Row>
            {ribbon > 0 &&
            <div className="mini-stat-label bg-success">
              <p className="mb-0">{ribbon}%</p>
            </div>
            }
            {ribbon < 0 &&
            <div className="mini-stat-label bg-danger">
              <p className="mb-0">{ribbon}%</p>
            </div>
            }
            {ribbon === 0 &&
            <div className="mini-stat-label bg-warning">
              <p className="mb-0">{ribbon}%</p>
            </div>
            }
          </Row>
          <Row>
            <Col sm={4}>
              <div className="float-left mini-stat-img mr-4">
                <img src={imgSrc} alt=""/>
              </div>
            </Col>

            <Row className="align-items-end">
              <Col>
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                  {title}
                </h5>
              </Col>
            </Row>

          </Row>
          <div className="mb-4">
            <h4 className="font-weight-medium font-size-24">
              {change}
              {arrow === "up" &&
              <i className="mdi mdi-arrow-up text-success ml-2"/>
              }
              {arrow === "down" &&
              <i className="mdi mdi-arrow-down text-danger ml-2"/>
              }
              {arrow === "noChange" &&
              <i className="mdi mdi-arrow-left-right text-warning ml-2"/>
              }
            </h4>

          </div>
          <div className="pt-2">
            <div className="float-right">
              {linkPath &&
              <Link to={linkPath} className="text-white-50">
                <i className="mdi mdi-arrow-right h5"/>
              </Link>
              }
            </div>
            <p className="text-white-50 mb-0 mt-1">Since last month</p>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default DashTile;
