import React from 'react';
import { elastic as Menu } from 'react-burger-menu';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <Menu>
      <a className="bm-item" href="/">
        <h3>Home</h3>
      </a>
      <h4>Train The Teacher</h4>
      <ul className="bm-item-list">
        <ol>
          <a className="bm-item" href="/ttt/users">
            Users
          </a>
        </ol>
        <ol>
          <a className="bm-item" href="/ttt/discount">
            Discount Codes
          </a>
        </ol>
        <ol>
          <a className="bm-item" href="/ttt/course">
            Courses
          </a>
        </ol>
      </ul>
      <h4>Tefl Consultants</h4>
      <ul className="bm-item-list">
        <ol>
          <a className="bm-item" href="/tc/user">
            Users
          </a>
        </ol>
        <ol>
          <a className="bm-item" href="/tc/book">
            Books
          </a>
        </ol>
        <ol>
          <a className="bm-item" href="/tc/consultancy">
            Consultancy
          </a>
        </ol>
      </ul>
      <button className="btn btn-primary" onClick={() => {
        localStorage.removeItem('api-token');
        window.location.reload();
      }}>Log out</button>
    </Menu>
  );
};
export default Sidebar;
