import React from 'react';
import { Redirect } from 'react-router-dom';

import DiscountList from "../pages/traintheteacher/discount/ListPage";
import DiscountEdit from "../pages/traintheteacher/discount/EditPage";
import CourseList from "../pages/traintheteacher/course/ListPage";
import CourseEdit from "../pages/traintheteacher/course/EditPage";
import TTTUsersEdit from "../pages/traintheteacher/users/EditPage";
import TTTUsersList from "../pages/traintheteacher/users/ListPage";
import TCUserEdit from "../pages/teflconsultants/user/EditPage";
import TCUserList from "../pages/teflconsultants/user/ListPage";
import HomePage from "../pages/home";
import TCBookEdit from "../pages/teflconsultants/book/EditPage";
import TCBookList from "../pages/teflconsultants/book/ListPage";
import TCConsultancyEdit from "../pages/teflconsultants/consultancy/EditPage";
import TCConsultancyList from "../pages/teflconsultants/consultancy/ListPage";



const authProtectedRoutes = [

  // TTT
  {path: '/ttt/users/:id', exact: true, component: TTTUsersEdit},
  {path: '/ttt/users', exact: true, component: TTTUsersList},

  {path: '/ttt/discount/:id', component: DiscountEdit},
  {path: '/ttt/discount/add', component: DiscountEdit},
  {path: '/ttt/discount', component: DiscountList},

  {path: '/ttt/course/add', component: CourseEdit},
  {path: '/ttt/course/:id', component: CourseEdit},
  {path: '/ttt/course', component: CourseList},

  // TC
  {path: '/tc/user/:id', exact: true, component: TCUserEdit},
  {path: '/tc/user', exact: true, component: TCUserList},

  {path: '/tc/book/add', exact: true, component: TCBookEdit},
  {path: '/tc/book/:id', exact: true, component: TCBookEdit},
  {path: '/tc/book', exact: true, component: TCBookList},

  {path: '/tc/consultancy/:id', exact: true, component: TCConsultancyEdit},
  {path: '/tc/consultancy/add', exact: true, component: TCConsultancyEdit},
  {path: '/tc/consultancy', exact: true, component: TCConsultancyList},

  // Common
  {path: '/home', exact: true, component: HomePage},

  { path: '/', exact: true, component: () => <Redirect to="/home" /> }
];

const publicRoutes = [

];

export { authProtectedRoutes, publicRoutes };
