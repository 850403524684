import {CardBody} from "reactstrap";
import SalesDonut from "./SalesDonut";

const SalesReport = ({props}) => {
  const {total, series, labels} = props;
  return(
    <>
        <div className="clearfix">
          <h5 className="font-18 text-center mb-0">{total}¥</h5>
        </div>
        <div id="ct-donut" className="ct-chart">
          <SalesDonut props={{series, labels}} />
        </div>
        {/*<div className="mt-4">*/}
        {/*  <table className="table mb-0">*/}
        {/*    <tbody>*/}
        {/*    <tr>*/}
        {/*      <td>*/}
        {/*        <span className="badge badge-primary">Desk</span>*/}
        {/*      </td>*/}
        {/*      <td>Desktop</td>*/}
        {/*      <td className="text-right">54.5%</td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td>*/}
        {/*        <span className="badge badge-success">Mob</span>*/}
        {/*      </td>*/}
        {/*      <td>Mobile</td>*/}
        {/*      <td className="text-right">28.0%</td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td>*/}
        {/*        <span className="badge badge-warning">Tab</span>*/}
        {/*      </td>*/}
        {/*      <td>Tablets</td>*/}
        {/*      <td className="text-right">17.5%</td>*/}
        {/*    </tr>*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*</div>*/}
    </>
  );
};
export default SalesReport;
