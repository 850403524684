import axios from './axios';
import { TTT_USER } from './urls';

export default {

  async getOne(id) {
    try {
      return await axios.get(TTT_USER.GET_OR_DELETE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async insert(params) {
    try {
      return await axios.post(TTT_USER.UPSERT, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async update(params) {
    try {
      return await axios.post(TTT_USER.UPDATE, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(TTT_USER.GET_OR_DELETE + id);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async getAll() {
    try {
      return await axios.get(TTT_USER.GET_ALL);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
};

