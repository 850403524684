import React, {useEffect, useState} from "react";
import bookAPI from '../../../api/book';
import DetailHeader from "../../../Components/DetailHeader";
import {Card, CardBody, Col, Row} from "reactstrap";
import GridTable from "../../../Components/GridTable";
import {Link} from "react-router-dom";

const headerInfo = {
  parentLabel: 'Home',
  parentLink: '/',
  current: 'Books',
  source: 'Tefl Consultants'
};

const columns = [
  {
    label: 'Title',
    field: 'title'
  },
  {
    label: 'Price',
    field: 'priceAdjusted'
  },
  {
    label: "Filename",
    field: "filename"
  },
  {
    label: '',
    field: 'getLink'
  }
];

const TCBookList = ({history}) => {
  const [apiData, setApiData] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(
      apiData.map(b => ({
        ...b,
        priceAdjusted: `${b.priceAdjusted}¥`,
        title: <Link to={`/tc/book/${b.id}`}>{b.title}</Link>,
        getLink: <button onClick={() => window.prompt("Copy to clipboard: Ctrl+C, Enter",`https://www.payments.teflconsultants.com/teflconsultants/alipay_payment.html?book=${b.id}`)}>Get Link</button>
      }))
    );
  }, [apiData]);

  useEffect(() => {
    async function fetchData() {
      const result = await bookAPI.getAll();
      console.log(result);
      if (result && Array.isArray(result)) {
        setApiData(result);
      } else {
        setApiData([]);
      }
    }
    fetchData();
  }, []);

  return(
    <>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Col sm={6}>
            <Row>
              <h4>Book list</h4>
            </Row>
          </Col>
        </CardBody>
        <GridTable data={{columns, rows: rowData}} evClickBtn={() => {history.push('/tc/book/add')}}/>
      </Card>
    </>
  );
};
export default TCBookList;
