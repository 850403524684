import React, {useEffect, useState} from "react";
import DetailHeader from "../../../Components/DetailHeader";
import {Card, Col, Row, CardBody} from "reactstrap";
import GridTable from "../../../Components/GridTable";
import userAPI from '../../../api/ttt_users';
import {Link} from "react-router-dom";
import {parseUnixTime} from "../../../Utils/time";
import {parseStatus} from "../../../Utils/alipay";

const headerInfo = {
  parentLabel: 'Home',
  parentLink: '/',
  current: 'User List',
  source: 'Train The Teacher'
};

const columns = [
  {
    label: 'id',
    field: 'id',
  },
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'Payment Amount',
    field: 'paymentAdjusted',
  },
  {
    label: 'Discount Code',
    field: 'discountCode',
  },
  {
    label: 'Allow Marketing?',
    field: 'allowMarketing',
  },
  {
    label: 'WeChat',
    field: 'wechatId',
  },
  {
    label: 'Phone',
    field: 'phoneNumber',
  },
  {
    label: 'Status',
    field: 'status'
  },
  {
    label: 'Date',
    field: 'date',
  },
];

const TTTUsersList = ({history}) => {
  const [apiData, setApiData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await userAPI.getAll();
      console.log(result);
      if (result && Array.isArray(result)) {
        result.reverse();
        setApiData(result);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setTableData(
      apiData.map(d => ({
        ...d,
        name: <Link to={`/ttt/users/${d.id}`}>{d.name}</Link>,
        email: <a href={`mailto: ${d.email}`}>{d.email}</a>,
        paymentAdjusted: `${d.paymentAdjusted}¥`,
        discountCode: <Link to={`/ttt/discount/${d?.discount?.id}`}>{d.discountCode}</Link>,
        allowMarketing: d.allowMarketing === 1 ? 'Yes' : 'No',
        status: parseStatus(d.status),
        date: parseUnixTime(d.date)
      }))
    );
  }, [apiData]);

  return(
    <>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Col sm={6}>
            <Row>
              <h4>Users list</h4>
            </Row>
          </Col>
        </CardBody>
      </Card>
      <GridTable data={{columns, rows: tableData}} />
    </>
  );
};
export default TTTUsersList;
