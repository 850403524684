export const findThisMonthStart = () => {
  const d = new Date();
  console.log(d.toISOString());
  const days = d.getDate();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();

  let millisThisMonth = 0;

  millisThisMonth += ((days - 1) * (86400 * 1000));
  millisThisMonth += ((hours - 1) * (3600 * 1000));
  millisThisMonth += ((minutes - 1) * (60 * 1000));
  millisThisMonth += ((seconds - 1) * 1000);

  return Date.now() - millisThisMonth;
};

export const findLastMonthStart = (monthEnd) => {
  const d = new Date();
  const month = d.getMonth(); // Jan = 0
  let millisLastMonth;
  // has 31 days
  if (month === 1 || month === 3 || month === 5 || month ===  7 || month ===  8 || month === 10 || month ===  12) {
    millisLastMonth = (86400 * 1000) * 31;
  } else if (month === 2) {
    millisLastMonth = (86400 * 1000) * 28; // fuck leap years cba
  } else {
    millisLastMonth = (86400 * 1000) * 30;
  }
  return monthEnd - millisLastMonth;
};

export const calcDateRangeForSalesReport = () => {
  const d = new Date();
  return `${parseMonthName(d.getMonth())} 1 ~ ${d.getDate()}`;

};

const parseMonthName = month => {
  switch (month) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default :
      return "";
  }
};

export const parseUnixTime = millis => {
  const d = new Date(millis);
  return `${d.getFullYear()}, ${parseMonthName(d.getMonth())} ${d.getDate()},  ${d.getHours()}:${d.getMinutes()}`;
};
